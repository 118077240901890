:root {
  --white: #fff;
  --yellow: #f6be42;
  --light-purple: #f3eef6;
  --dark-purple: #c0b5e2;
  --green: #9fae85;
  --blue: #1c2f60;
  --rusty-red: #ed6434;
}

html {
  scroll-behavior: smooth;
}

.section {
  color: var(--white);
  position: relative;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.image__header {
  font-family: "MonumentGroteskMono";
  font-size: 1.5rem;
  letter-spacing: 0.2em;

  margin-bottom: 32px;
  width: 100%;
}

.gutter {
  margin-left: auto;
  margin-right: auto;
  width: 1060px;
}

.section__content {
  font-family: Times New Roman;
  font-size: 80px;
  font-weight: 400;
  line-height: 96px;
  letter-spacing: 0em;
  text-align: left;

  padding-top: 380px;
  padding-bottom: 183px;
}

@media (max-width: 1124px) {
  .section__content {
    padding-top: 250px;
    padding-bottom: 128px;
    font-size: 57px;
    line-height: 68px;
  }

  .gutter {
    width: 760px;
  }
}

@media (max-width: 778px) {
  .image__header {
    font-size: 0.9375rem;
  }

  .section__content {
    padding-top: 136px;
    padding-bottom: 120px;
    font-size: 32px;
    line-height: 38px;
  }

  .gutter {
    width: 420px;
  }
}

@media (max-width: 419px) {
  .gutter {
    width: 85%;
  }
}

.tooltip {
  opacity: 0;

  transition: opacity 500ms ease-in-out;
  -moz-transition: opacity 500ms ease-in-out;
  -webkit-transition: opacity 500ms ease-in-out;
}

.show {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
  -moz-transition: opacity 500ms ease-in-out;
  -webkit-transition: opacity 500ms ease-in-out;
}

.yellow {
  color: var(--yellow);
}

.dark-purple {
  color: var(--dark-purple);
}

.green {
  color: var(--green);
}

.rusty-red {
  color: var(--rusty-red);
}

