.loading-page {
  height: 100vh;
  width: 100vw;

  background-color: #C0B5E2;
}

.loading-page__spinner {
  margin: auto;
  width: 100px;
  height: 100%;
}

@media (max-width: 1024px) {
  .loading-page__title {
    font-size: 100px;
  }
}

@media (max-width: 778px) {
  .loading-page__title {
    font-size: 60px;
  }
}
